import shopifyEnterpriseGenericDefaults from '../shopify-enterprise-generic/config.js';
import { addVehicleToCartProduct } from '../_common/shopifyCartProductVehicle.js';

const wheelsFields = shopifyEnterpriseGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyEnterpriseGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const isCartPage = window.location.pathname === '/cart';

shopifyEnterpriseGenericDefaults.Widgets.map((w) => {
  if (w.name === 'FilterChips' || w.name === 'Facets' || w.name === 'FacetDialog') {
    if (w.ignoreFields) {
      w.ignoreFields.push('wheel_aggressive');
    } else {
      w.ignoreFields = ['wheel_aggressive'];
    }
  }
  return w;
});

function responseHandler(response) {
  const tpmsBannerEl = window.document.querySelector('.cm_tpms-banner');

  if (tpmsBannerEl) {
    tpmsBannerEl.classList.toggle('cm_hide', !response.Messages?.includes('VehicleHasTPMS'));
  }

  return response;
}

globalThis.Convermax.onCartDialogSubmit = (e) => {
  e.preventDefault();

  const formData = new FormData(e.target);
  const selectedOption = formData.get('vehicle-option');
  const customInput = formData.get('custom-vehicle-input')?.trim();

  if (selectedOption === 'select-vehicle') {
    const selectVehicleButton = document.querySelector(
      '.cm_vehicle-dialog-form .cm_vehicle-widget_button__go:not([disabled])',
    );
    if (selectVehicleButton) {
      selectVehicleButton.click();
    }
  } else if (selectedOption === 'type-vehicle') {
    setCustomVehicleAtShopifyCart(customInput);
    localStorage.setItem('cm-user-vehicle', customInput);
  }

  window.document.querySelector('#cm_CartPageVehicleDialog .close')?.click();
};

globalThis.Convermax.onCartDialogChange = ({ target }) => {
  if (target.name === 'vehicle-option') {
    const form = target.closest('.cm_vehicle-dialog-form');
    if (form) {
      form
        .querySelectorAll('.cm_tab')
        .forEach((tab) => tab.classList.toggle('active', tab.dataset.tab === target.value));
    }
  }
};

globalThis.Convermax.openVerifyFitmentDialog = (e) => {
  const isVerifyFitmentActive = window.document.querySelector('#cm-verify-fitment .cmTemplate_active');

  if (isVerifyFitmentActive) {
    e.preventDefault();
    e.stopPropagation();

    window.Convermax.openDialog('VerifyFitmentDialog');
  }
};

const onVerifyFitmentResponseReceived = (response) => {
  const addToCartButton = window.document.querySelector('.product-info__add-button button');

  if (addToCartButton) {
    const fits = response.Items?.[0]?.fitsTheVehicle;

    if (fits && fits === 'yes') {
      addToCartButton.removeAttribute('disabled');
    } else if (fits && fits === 'no') {
      addToCartButton.disabled = 'true';
    }
  }
};

function setCustomVehicleAtShopifyCart(vehicleStr) {
  fetch(`${window?.Shopify.routes.root}cart/update.js`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ attributes: { ['YMMText']: vehicleStr } }),
  }).catch(console.error);
}

function AfterInit() {
  if (isCartPage && !window.Convermax.isVehicleSelected() && !localStorage.getItem('cm-user-vehicle')) {
    window.document.querySelector('#cm_CartPageVehicleDialogButton .cm_button')?.click();
  }
}

const InitFunc = () => {
  shopifyEnterpriseGenericDefaults.InitFunc?.();

  window.document.body.addEventListener('on:cart:add', (e) =>
    addVehicleToCartProduct({ variantId: e.detail?.variantId, cart: e.detail?.cart }),
  );
};

export default {
  includes: ['shopify-enterprise-generic'],
  ...shopifyEnterpriseGenericDefaults,
  InitFunc,
  AfterInit,
  responseHandler,
  SearchRequestDefaults: {
    ...shopifyEnterpriseGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifyEnterpriseGenericDefaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  fitmentSearch: {
    ...shopifyEnterpriseGenericDefaults.fitmentSearch,
    isAutoRedirectDisabled: isCartPage,
    isAutoVehicleSelectionDisabled: isCartPage,
    isVehicleSelectionIsolated: true,
    onVerifyFitmentInitResponseReceived: onVerifyFitmentResponseReceived,
    onVerifyFitmentResponseReceived,
  },
  facets: {
    ...shopifyEnterpriseGenericDefaults.facets,
    toggleFacet: [{ fields: ['wheel_aggressive'], term: 'Yes' }],
  },
  Widgets: [
    ...shopifyEnterpriseGenericDefaults.Widgets.filter(
      (w) => !['FacetBarWheels', 'FacetBarTires', 'VehicleWidgetDialog'].includes(w.name),
    ),
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-home-ymm',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm-home-wheels',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: wheelsFields,
      onSubmit: () => window.Convermax.discardVehicle(),
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm-home-tire',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
      onSubmit: () => window.Convermax.discardVehicle(),
    },
    {
      name: 'HomeVehicleWidget_tab-wheels',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-home-vehicle-wheel',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      redirectUrl: 'collections/wheels',
    },
    {
      name: 'HomeVehicleWidget_tab-tires',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-home-vehicle-tire',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      redirectUrl: 'collections/tires',
    },
    {
      name: 'SearchBox_tab',
      type: 'SearchBox',
      location: {
        selector: '#cm-home-search-box',
        class: 'cm_search-box-root__tab',
      },
      template: 'SearchBox',
      disableDropdown: true,
    },
    {
      name: 'HeaderVehicleWidget_cart', // to send request with ymm to get TPMS info on the cart page
      type: 'VehicleWidget',
      location: {
        lastChildOf: '.footer',
        class: 'cm_vehicle-widget__header cm_hide',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      doNotRedirectOnVehicleSelect: true,
      visibleIf: () => isCartPage,
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter', 'wheel_aggressive'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'VehicleWidgetDialog',
      location: { class: 'vehicle-dialog' },
      template: 'fitmentSearch/dialog',
      doNotRedirectOnVehicleSelect: isCartPage,
      fields:
        !!window.Convermax.config?.extraFieldsAsFilters &&
        shopifyEnterpriseGenericDefaults.fitmentSearch.baseFields,
    },
    {
      name: 'VerifyFitment_dialog',
      type: 'VerifyFitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'VerifyFitmentDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/verifyFitmentDialog',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      doNotRedirectOnVehicleSelect: true,
      isAlwaysColumnLayout: true,
      isAlwaysActive: true,
    },
    {
      name: 'CartPageVehicleDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/cartPageVehicleDialog',
    },
    {
      name: 'CartPageVehicleDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'CartPageVehicleDialog',
      metadata: { vehicleWidgetIncluded: true },
      visibleIf: () => document.querySelector('#main-content > .cc-main-cart'),
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      minFacetCount: 2,
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      minFacetCount: 2,
      fields: tiresFields,
    },
  ],
};
