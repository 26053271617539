async function addVehicleToCartProduct({ variantId, cart }) {
  if (!variantId) {
    return;
  }

  const vehicleString = window.Convermax.getVehicleString();
  if (!vehicleString) {
    return;
  }

  const currentCart = cart ?? (await getCart());

  const cartItems = currentCart?.items;
  if (!cartItems?.length) {
    return;
  }

  const addedItem = cartItems.find((item) => item.id === variantId);

  if (!addedItem) {
    return;
  }

  const updateData = {
    id: addedItem.id.toString(),
    properties: { ...addedItem.properties, Vehicle: vehicleString },
  };

  await fetch(`${window?.Shopify.routes.root}cart/change.js`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updateData),
  });
}

async function getCart() {
  const response = await fetch(`${window?.Shopify.routes.root}cart.js`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to get cart: ${response.status}`);
  }

  return await response.json();
}

export { addVehicleToCartProduct };
